#page-template-bloc-condition {
  position: absolute;
}

#page-template-bloc-condition .block-grid {
  cursor: pointer;
  position: relative;
}

#page-template-bloc-condition .block-grid:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  border: #30426a 2px dashed;
  z-index: 1;
}

#page-template-bloc-condition .block-grid:hover:after {
  background-color: rgba(233, 157, 26, 0.9);
  background-image: url(/assets/images/pencil.svg);
  background-size: 20px 20px;
  visibility: visible;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: top right;
}


