@import '_variables.scss';

body {
  margin: 0;
  padding: 0;
}

.loader {
  width: auto !important;
  color: var(--primary) !important;
}

.fixed-height-card-body {
  max-height: 400px;
  overflow-y: auto;
}

.no_padding {
  padding: 0 !important;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1140px) {
  .header-brand{
    width: 80px;
    text-align: right;
  }
}

.page-main .header.collapse {
display: none !important;
}

.react-toast-notifications__container {
  z-index: 5000 !important;
}
.header-toggler {
 display: none;
}
/* Position and sizing of burger button */
#react-burger-menu-btn:focus {
  border: none;
  outline: 0px;
}
.bm-burger-button {
  position: fixed;
  width: 22px;
  height: 16px;
  left: 15px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $gray;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

// BURGER MENY -> NAV BAR
.bm-item-list .nav.nav-tabs {
  display: block;
}

.btn-primary {
    background-color: var(--primary) !important;
}

.page-item.active .page-link {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}
.badge-primary {
    background-color: var(--primary) !important;
}
