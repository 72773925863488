.container{
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em !important;
  color: #9aa0ac;
  .icon{
    font-size: 2em;
    margin-bottom: 20px;
    display: inline-block;
  }
  h3{
    text-align: center;
  }
}